import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token, redirect } = useSelector(state => state.auth)

	// return token ? <Navigate to={redirect} /> : <Outlet />
	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />
}

export default PublicRoute