import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "otp-screen",
    path: `${AUTH_PREFIX_PATH}/otp-screen`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/otp-screen")
    ),
  },
  {
    key: "error-page",
    path: `${AUTH_PREFIX_PATH}/error-page`,
    component: React.lazy(() => import("views/auth-views/errors/error-page")),
  },
  {
    key: "privacy",
    path: `/privacy-policy`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/privacy")
    ),
  },
  {
    key: "terms",
    path: `/terms-and-conditions`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/terms")
    ),
  }
];

export const protectedRoutes = [
  // {
  //   key: "dashboard.default",
  //   path: `${APP_PREFIX_PATH}/dashboards/default`,
  //   component: React.lazy(() => import("views/app-views/apps/pages/dashboard")),
  // },
  {
    key: "apps.dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/pages/dashboard")),
    permission_type: 'all',
  },
  {
    key: "apps.customerdetails",
    path: `${APP_PREFIX_PATH}/customer-details`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/customer-details")
    ),
    permission_type: 'view_customers',
  },
  {
    key: "apps.addStation",
    path: `${APP_PREFIX_PATH}/add-e20`,
    component: React.lazy(() => import("views/app-views/apps/pages/add-e20")),
    permission_type: 'create_e20_fuel_stations',
  },
  {
    key: "apps.editStation",
    path: `${APP_PREFIX_PATH}/edit-e20/:id`,
    component: React.lazy(() => import("views/app-views/apps/pages/edit-e20")),
    permission_type: 'all',
  },
  {
    key: "apps.registeredStation",
    path: `${APP_PREFIX_PATH}/registered-station`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/registered-station")
    ),
    permission_type: 'view_e20_fuel_stations',
  },
  // {
  //   key: "apps.report",
  //   path: `${APP_PREFIX_PATH}/report`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/pages/report")
  //   ),
  // },
  {
    key: "apps.e20FuelDistirbution",
    path: `${APP_PREFIX_PATH}/report/e20-fuel-distribution`,
    title: "e20-fuel-distirbution",
    component: React.lazy(() =>
      import("views/app-views/apps/pages/report/e20-fuel-distribution")
    ),
    permission_type: 'view_reports',
  },
  {
    key: "apps.e20CustomerDistribution",
    path: `${APP_PREFIX_PATH}/report/e20-customer-distribution`,
    title: "e20-customer-distribution",
    component: React.lazy(() =>
      import("views/app-views/apps/pages/report/e20-customer-distribution")
    ),
    permission_type: 'view_reports',
  },
  {
    key: "apps.sub-admin",
    path: `${APP_PREFIX_PATH}/manage-sub-admin`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/manage-sub-admin")
    ),
    permission_type: 'view_sub_admins',
  },
  {
    key: "apps.pincode",
    path: `${APP_PREFIX_PATH}/manage-pincode`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/manage-pincode")
    ),
    permission_type: 'view_pincode',
  },
  {
    key: "apps.vehiclebrand",
    path: `${APP_PREFIX_PATH}/vehicle-brands`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/vehicle-brand")
    ),
    permission_type: 'view_vehicle',
  },
  {
    key: "apps.vehicletype",
    path: `${APP_PREFIX_PATH}/vehicle-type`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/vehicle-type")
    ),
    permission_type: 'view_vehicle',
  },
  {
    key: "apps.vehiclemodel",
    path: `${APP_PREFIX_PATH}/vehicle-model`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/vehicle-model")
    ),
    permission_type: 'view_vehicle',
  },
  {
    key: "apps.faq",
    path: `${APP_PREFIX_PATH}/faq`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/faq")
    ),
    permission_type: 'view_faqs',
  },
  {
    key: "apps.notification",
    path: `${APP_PREFIX_PATH}/notification`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/notification")
    ),
    permission_type: 'create_notifications',
  },
  {
    key: "apps.knowledge",
    path: `${APP_PREFIX_PATH}/knowledge`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/knowledge")
    ),
    permission_type: 'view_knowledge_corner',
  },
  {
    key: "apps.termscondition",
    path: `${APP_PREFIX_PATH}/terms-condition`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/terms-condition")
    ),
    permission_type: 'all',
  },
  {
    key: "apps.privacypolicy",
    path: `${APP_PREFIX_PATH}/privacy-policy`,
    component: React.lazy(() =>
      import("views/app-views/apps/pages/privacy-policy")
    ),
    permission_type: 'all',
  },
  // {
  //   key: "pages",
  //   path: `${APP_PREFIX_PATH}/pages`,
  //   component: React.lazy(() => import("views/app-views/pages")),
  //   permission_type: 'all',
  // },
  // {
  //   key: "pages.profile",
  //   path: `${APP_PREFIX_PATH}/profile`,
  //   component: React.lazy(() => import("views/app-views/pages/profile")),
  //   permission_type: 'all',
  // },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
    permission_type: 'all',
  },
  {
    key: "pages.logs",
    path: `${APP_PREFIX_PATH}/logs`,
    component: React.lazy(() => import("views/app-views/apps/pages/logs")),
    permission_type: 'all',
  },
];
