import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { axiosGet, axiosPost } from 'configs/AxiosConfig';
import { message } from 'antd';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: localStorage.getItem('redirect') || '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	isSuperAdmin: false,
	permissions: []
}

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	return new Promise((resolve, reject) => {
		axiosPost('api/admins/login', data).then((res) => {
			if (res && res.status == 200) {
				console.log("Res--", res)
				let { token, isSuperAdmin, user, permissions, password_expired } = res
				let redirect = password_expired ? '/app/setting/change-password' : '/app/dashboard'

				// token = JSON.stringify(token)
				localStorage.setItem(AUTH_TOKEN, token);
				localStorage.setItem('user', JSON.stringify(user));
				localStorage.setItem('redirect', redirect);

				resolve({ token, isSuperAdmin, user, permissions, redirect })
			}
			else if (res && (res.code == 'email_not_exists' || res.code == "invalid"))
				message.error("Inavlid email/password")
			else if (res && res.code == 'server_error')
				message.error("Server error")
			resolve(rejectWithValue(res?.code))
		}).catch((err) => {
			message.error("Server error")
			resolve(rejectWithValue('Something went Wrong!! Try again'))
		})
	})
})

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	return new Promise((resolve, reject) => {
		axiosPost('api/admins/add', data).then((res) => {
			if (res && res.status == 200) {
				let { token, isSuperAdmin, user, permissions } = res
				// token = JSON.stringify(token)
				localStorage.setItem(AUTH_TOKEN, token);
				resolve({ token, isSuperAdmin, user, permissions })
			} else {
				resolve(rejectWithValue('Something went Wrong!! Try again'))
			}
		}).catch((err) => {
			console.log("Error -- ", err)
			resolve(rejectWithValue('Something went Wrong!! Try again'))
		})
	})
})

export const permissions = createAsyncThunk('auth/permissions', async (data, { rejectWithValue }) => {
	return new Promise((resolve, reject) => {
		axiosGet('api/admins/permissions').then((res) => {
			if (res && res.status == 200) {
				let { isSuperAdmin, admin, permissions } = res
				resolve({ isSuperAdmin, admin, permissions })
			} else {
				localStorage.removeItem(AUTH_TOKEN);
				resolve(rejectWithValue('Something went Wrong!! Try again'))
			}
		}).catch((err) => {
			console.log("Error -- ", err)
			resolve(rejectWithValue('Something went Wrong!! Try again'))
		})
	})
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
	localStorage.removeItem(AUTH_TOKEN);
	return true
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = action.payload.redirect
				state.token = action.payload.token
				state.isSuperAdmin = action.payload.isSuperAdmin
				state.permissions = action.payload.permissions || []
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.isSuperAdmin = action.payload.isSuperAdmin
				state.permissions = action.payload.permissions || []
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(permissions.fulfilled, (state, action) => {
				state.loading = false
				state.isSuperAdmin = action.payload.isSuperAdmin
				state.permissions = action.payload.permissions
			})

	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer